import { ISidebarRoute } from "../sidebar.types";

export const sidebarRoutes: ISidebarRoute[] = [
  {
    path: "/store",
    translate: "store",
    role: "store",
  },
  {
    path: "/order",
    translate: "order",
    role: "order",
  },
  {
    path: "/category",
    translate: "category",
    role: "storeProductCategory",
  },
  {
    path: "/state",
    translate: "state",
    role: "orderState",
  },
  {
    path: "/admin",
    translate: "admin",
    role: "admin",
  },
  // {
  //   path: "/courier",
  //   translate: "courier",
  //   role: "admin",
  // },
  {
    path: "/role",
    translate: "role",
    role: "role",
  },
  {
    path: "/banner",
    translate: "banner",
    role: "banner",
  },
  {
    path: "/rating",
    translate: "rating",
    role: "rateComment",
  },
  {
    path: "/measure",
    translate: "measure",
    role: "measure",
  },
  {
    path: "/product",
    translate: "product",
    role: "product",
  },
  {
    path: "/employee",
    translate: "employee",
    role: "employee",
  },
  {
    path: "/customer",
    translate: "customer",
    role: "customer",
  },
  {
    path: "/transaction",
    translate: "transaction",
    role: "transaction",
  },
  {
    path: "/integration",
    role: "integration",
    translate: "integration",
  },
  {
    path: "/settings",
    translate: "settings",
    role: "settings",
  },
];
