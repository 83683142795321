import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { IRoleData } from "store/reducers/LoginSlice";

const BLACK_PAGE = lazy(() => import("pages/black_page"));
const Store = lazy(() => import("pages/store"));
const State = lazy(() => import("pages/state"));
const Banner = lazy(() => import("pages/banner"));
const Rating = lazy(() => import("pages/rating"));
const Measure = lazy(() => import("pages/measure"));
const Order = lazy(() => import("pages/order"));
const Customer = lazy(() => import("pages/customer"));
const Product = lazy(() => import("pages/product"));
const Category = lazy(() => import("pages/category"));
const Employee = lazy(() => import("pages/employee"));
const Role = lazy(() => import("pages/role"));
const Admin = lazy(() => import("pages/admin"));
const Courier = lazy(() => import("pages/courier"));
const Settings = lazy(() => import("pages/settings"));
const Transaction = lazy(() => import("pages/transaction"));
const Integration = lazy(() => import("pages/integration"));

const privateRoutes: (RouteObject & { role: keyof IRoleData })[] = [
  {
    element: <Navigate to="/home" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/home" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "/home",
    role: "_id",
  },
  {
    element: <Store />,
    path: "/store",
    role: "store",
  },
  {
    element: <Category />,
    path: "/category",
    role: "storeProductCategory",
  },
  {
    element: <State />,
    path: "/state",
    role: "orderState",
  },
  {
    element: <Banner />,
    path: "/banner",
    role: "banner",
  },
  {
    element: <Rating />,
    path: "/rating",
    role: "rateComment",
  },
  {
    element: <Measure />,
    path: "/measure",
    role: "measure",
  },
  {
    element: <Order />,
    path: "/order/*",
    role: "order",
  },
  {
    element: <Customer />,
    path: "/customer",
    role: "customer",
  },
  {
    element: <Product />,
    path: "/product",
    role: "product",
  },
  {
    element: <Employee />,
    path: "/employee",
    role: "employee",
  },
  {
    element: <Admin />,
    path: "/admin",
    role: "admin",
  },
  // {
  //   element: <Courier />,
  //   path: "/courier",
  //   role: "admin",
  // },
  {
    element: <Role />,
    path: "/role",
    role: "role",
  },
  {
    element: <Transaction />,
    path: "/transaction",
    role: "transaction",
  },
  {
    element: <Settings />,
    path: "/settings",
    role: "settings",
  },
  {
    element: <Integration />,
    path: "/integration",
    role: "integration",
  },
];

export default privateRoutes;
